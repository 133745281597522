<template>
    <button v-if="loaded" class="buttonDeal w-99 rounded-xl p-2 m-1 h-64">
        <div class="bg-gray-100 rounded-xl shadow-md my-1">
            <div class="flex justify-between h-full bg-gray-100 rounded-xl">
                <a class="flex-shrink-0 w-28 md:w-36 bg-white rounded-l-lg overflow-hidden flex items-center justify-center">
                    <img class="object-contain h-32 w-32 p-4" :src="product.image" alt="Product Image"/>
                </a>
                <div class="w-full">
                    <div class="pt-6 md:p-8 text-center w-full">
                        <p class="text-lg mx-2 font-medium text-black truncate-3-lines">{{ product.title }}</p>
                        <div class="mt-1">
                            <div v-if="product.noDealPrice && product.noDealPrice > product.price" class="grid grid-cols-1 place-content-center place-items-center">
                                <div class="flex w-fit place-content-center">
                                    <s class="text-slate-700 w-fit mx-1">{{ product.noDealPrice.toFixed(2) }}€</s>
                                    <p class="text-white bg-red-600 rounded w-fit px-1 mx-1 text-sm">-{{ parseInt(100 - (product.price * 100) / product.noDealPrice) }}%</p>
                                </div>
                            </div>
                            <p v-if="product.price" class="text-sky-500 text-xl font-bold">{{ product.price.toFixed(2) }}€</p>
                            <p v-else class="text-sky-500 text-lg font-bold">NON DISPONIBILE</p>
                            <div v-if="product.hasCoupon" class="text-sky-500 text-3xl">
                                🎫
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class=" bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-2 rounded-full" :href="getUrl(product)" @click="openToAmazon(product)">
            Acquista ora su Amazon
        </button>
    </button>
</template>

<script>
export default {
    name: "productBox",
    data() {
        return {
            loaded: false,
            asin: (new URLSearchParams(window.location.search)).get('asin'),
            smallTitle: (new URLSearchParams(window.location.search)).get('smalltitle') === 'true'
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loading = true;
            this.$axios
                .get(`${this.$root.selectedServerDomain}/getProduct?asin=${this.asin}`, { headers: { "Bypass-Tunnel-Reminder": 2 } })
                .then((response) => {
                    this.product = response.data.product;
                    if (this.smallTitle && this.product.title.length > 50) {
                        //this.product.title = this.product.title.slice(0, 50) + "...";
                    }
                    this.loaded = true;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openToAmazon(product) {
            window.open(`https://www.amazon.it/dp/${product.asin}/?tag=ronchitom-21`, '_blank');
        },
        getUrl(product){
            return `https://www.amazon.it/dp/${product.asin}/?tag=ronchitom-21`
        }
    }
}
</script>

<style scoped>
.truncate-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  w-99 {
    width: 99%;
  }
</style>
