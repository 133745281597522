<template>
    <div v-if="loading" class="p-5 z-0">
        <svg role="status" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600 mt-6"
            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
            <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
        </svg>
    </div>
    <div v-if="errored" class="p-5 z-0">
        <h1> ERROR </h1>
    </div>

    <figure v-if="!loading" class="justify-between rounded-xl my-1 object-centre object-contain relative md:flex">
        <div class="2-full grid place-items-center">
            <img class="object-contain w-4/6 md:w-full bg-white m-2 rounded-xl p-4" :alt="product.title" :src="product.image" @click="openToAmazon(product)" :href="'https://amazon.it/dp/'+product.asin+'?tag=ronchitom_21'" />
            <div class="w-64 h-1"></div>
        </div>
        <div class="pt-6 md:p-8 text-center xl:w-5/6 md:w-4/6">
            <h1 class="text-xl font-medium m-2" @click="openToAmazon(product)" :href="'https://amazon.it/dp/'+product.asin+'?tag=ronchitom_21'">
                {{ product.title }}
            </h1>
            <div class="mt-1 ">
                <div v-if="product.noDealPrice != null && product.noDealPrice > product.price" class="grid grid-cols-1 place-content-center place-items-center">
                    <div class="grid grid-cols-2 w-fit place-items-center place-content-center">
                        <p class="text-slate-700 w-fit mx-1">
                            {{ product.noDealPrice.toFixed(2) }}€
                        </p>
                        <p class="text-white bg-red-600 rounded w-fit px-1 mx-1 text-sm">
                            -{{ parseInt(100 - (product.price * 100) / product.noDealPrice) }}%
                        </p>
                    </div>
                </div>
                <p v-if="product.price != null" class="text-sky-500">{{ product.price.toFixed(2) }}€</p>
                <p v-if="product.price == null" class="text-sky-500">Non disponibile</p>

                <h1 v-if="product.hasCoupon" class="text-slate-500">
                    Coupon: {{ product.coupon }}
                </h1>
                <div v-if="product.specs != undefined && product.specs.length > 0">
                    <div class="m-4 relative" @click="isExpanded = !isExpanded">
                        <div
                            class="bg-gray-100 p-2 cursor-pointer rounded-xl text-xs animation-exp"
                            :class="{ 'h-auto': isExpanded, 'pb-6': isExpanded }"
                        >
                            <h3 class="truncate font-bold">
                                Descrizione:
                            </h3>
                            <p class="truncate " v-if="!isExpanded">
                                {{product.specs[0]}}...
                            </p>
                            <transition-expand>
                                <div v-show="isExpanded">
                                    <p v-for="row in product.specs" :key="row">
                                        {{ row }}
                                    </p>
                                </div>
                            </transition-expand>
                        </div>
                        <div v-if="!isExpanded" class="absolute bottom-2 right-2 text-xs text-gray-500 bg-white p-px rounded-md">
                            Altro
                        </div>
                        <div v-if="isExpanded" class="absolute bottom-2 right-2 text-xs text-gray-500 bg-white p-px rounded-md ">
                            Mostra meno
                        </div>
                    </div>
                </div>
                <div>
                    <chartPrice :serverDomain="this.$root.selectedServerDomain" :asin="product.asin"></chartprice>
                </div>
                <div class="flex my-2 place-content-center">
                    <p class="text-slate-500 mx-5 text-xs">
                        Categoria: {{ product.category }}
                    </p>
                    <p class="text-slate-500 mx-5 text-xs">
                        Ultima verifica: {{ product.lastCheck }}
                    </p>
                </div>
            </div>
            <button v-if="pc" class="bg-blue-500 hover:bg-blue-700 text-white text-xl font-bold py-2 px-2 rounded-full m-2 "
                @click="openToAmazon(product)" :href="'https://amazon.it/dp/'+product.asin+'?tag=ronchitom_21'">
                Acquista ora
            </button>
        </div>
        <div v-if="!pc" class="fixed bottom-0 left-0 right-0 " :class="{'flex':webShareApiSupported()}">
            <button class="w-11/12 bg-blue-500 hover:bg-blue-700 text-white text-xl font-bold py-2 px-2 rounded-full m-2"
                @click="openToAmazon(product)" :href="'https://amazon.it/dp/'+product.asin+'?tag=ronchitom_21'">
                Acquista ora
            </button>
            <button v-if="webShareApiSupported()"  class="bg-gray-200 hover:bg-gray-300 rounded-full text-white m-2" @click="shareViaWebShare(product.asin)">
                <svg class="m-1" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M727-80q-47.5 0-80.75-33.346Q613-146.693 613-194.331q0-6.669 1.5-16.312T619-228L316-404q-15 17-37 27.5T234-366q-47.5 0-80.75-33.25T120-480q0-47.5 33.25-80.75T234-594q23 0 44 9t38 26l303-174q-3-7.071-4.5-15.911Q613-757.75 613-766q0-47.5 33.25-80.75T727-880q47.5 0 80.75 33.25T841-766q0 47.5-33.25 80.75T727-652q-23.354 0-44.677-7.5T646-684L343-516q2 8 3.5 18.5t1.5 17.741q0 7.242-1.5 15Q345-457 343-449l303 172q15-14 35-22.5t46-8.5q47.5 0 80.75 33.25T841-194q0 47.5-33.25 80.75T727-80Zm.035-632Q750-712 765.5-727.535q15.5-15.535 15.5-38.5T765.465-804.5q-15.535-15.5-38.5-15.5T688.5-804.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm-493 286Q257-426 272.5-441.535q15.5-15.535 15.5-38.5T272.465-518.5q-15.535-15.5-38.5-15.5T195.5-518.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm493 286Q750-140 765.5-155.535q15.5-15.535 15.5-38.5T765.465-232.5q-15.535-15.5-38.5-15.5T688.5-232.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5ZM727-766ZM234-480Zm493 286Z"/></svg>
            </button>
        </div>
    </figure>
    <div v-if="!loading" class=" border-t border-gray-300"></div>
    <similarProduct v-if="!loading" :asin="product.asin" :categoria="product.category" :titolo="product.title" />
    <div v-if="!loading" class=" border-t border-gray-300"></div>
    <boxRecensioni v-if="!loading" :reviews="product.reviewsTexts" :rating="product.rating"/>
</template>

<script>
import chartPrice from "./chart.vue";
import { TransitionExpand } from '@morev/vue-transitions';
import similarProduct from './similarProduct.vue'
import boxRecensioni from './boxRecensioni.vue'

export default {
    name: "productView",
    data() {
        return {
            product: null,
            loading: true,
            errored:false,
            pc:window.screen.width>768?true:false,
            isExpanded: this.$root.isprerenderrequest,  //false se non è in prerendering
        };
    },
    props: {
        asin: String,
    },
    components:{
        chartPrice,
        TransitionExpand,
        similarProduct,
        boxRecensioni
    },
    mounted() {
        this.loadData()
        window.onresize = ()=>{
            this.pc=window.screen.width>768?true:false
        };
    },
    methods: {
        loadData(){
            this.loading = true
            this.$axios
			.get(`${this.$root.selectedServerDomain}/getProduct?asin=${this.asin}`, { headers:{ "Bypass-Tunnel-Reminder": 2} }) 
			.then((response) => {
                console.log(response)
				this.product = response.data.product
                this.loading = false
                document.title = `${response.data.product.title} | piovonoofferte.it` 
                this.metataggen(this.product)
                // Open Graph Meta Tags
                this.addOpenGraphTags(response.data.product);
			})
			.catch((error) => {
				console.log(error);
				this.errored = true;
                this.loading = false
			})
			.finally(() => {this.loading = false});
        },
        metataggen(product){
            // Meta Description
            const metaDescription = document.querySelector('meta[name="description"]');
            if(product.price != null){
                metaDescription.content = `Scopri l'offerta per ${product.title} a ${product.price.toFixed(2)}€. Trova il miglior prezzo e approfitta dello sconto del ${parseInt(100 - (product.price * 100) / product.noDealPrice)}%!`;
            }else{
                metaDescription.content = `Scopri l'offerta per ${product.title}. Trova il miglior prezzo e approfitta dello sconto!`;
            }
            document.getElementsByTagName('head')[0].appendChild(metaDescription);

            // Canonical URL
            const linkCanonical = document.createElement('link');
            linkCanonical.rel = 'canonical';
            linkCanonical.href = window.location.href;
            document.getElementsByTagName('head')[0].appendChild(linkCanonical);
        },
        addOpenGraphTags(product) {
            const ogTitle = document.createElement('meta');
            ogTitle.setAttribute('property', 'og:title');
            ogTitle.content = `${product.title} - Piovono Offerte`;
            document.getElementsByTagName('head')[0].appendChild(ogTitle);

            const ogDescription = document.createElement('meta');
            ogDescription.setAttribute('property', 'og:description');
            if(product.price != null){
                ogDescription.content = `Scopri l'offerta per ${product.title} a ${product.price.toFixed(2)}€. Trova il miglior prezzo e approfitta dello sconto del ${parseInt(100 - (product.price * 100) / product.noDealPrice)}%!`;
            }else{
                ogDescription.content = `Scopri l'offerta per ${product.title}. Trova il miglior prezzo e approfitta dello sconto!`;
            }
            document.getElementsByTagName('head')[0].appendChild(ogDescription);

            const ogUrl = document.createElement('meta');
            ogUrl.setAttribute('property', 'og:url');
            ogUrl.content = window.location.href;
            document.getElementsByTagName('head')[0].appendChild(ogUrl);

            const ogImage = document.createElement('meta');
            ogImage.setAttribute('property', 'og:image');
            ogImage.content = product.image;
            document.getElementsByTagName('head')[0].appendChild(ogImage);
        },
        openToAmazon(product){
			window.open(`https://www.amazon.it/dp/${product.asin}/?tag=ronchitom-21`, '_blank');
		},
        shareViaWebShare(asin) {
            navigator.share({
                url: `https://piovonoofferte.it/?product=${asin}`
            })
        },
        webShareApiSupported() {
            return navigator.share
        },
    }
}
</script>

<style>
.animation-exp{
    transition: 0.5s linear;
}
</style>
