import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import './style.css'
import LazyLoad from "lazy-load-vue3";
import './registerServiceWorker'
import { pluginPwaInstallPrompt } from '@d-gs/vue-pwa-install-prompt'
import VueTransitions from '@morev/vue-transitions';
import '@morev/vue-transitions/styles';
import { createRouter, createWebHistory } from 'vue-router'
import alldeals from './components/AllDeals.vue'
import allPost from './components/blog/allpost.vue'


const routes = [
    {
        path: '/',
        component: alldeals
    },
    {
        path: '/blog',
        component: allPost
    },
    {
        path: '/blog/:name',
        component: allPost
    }
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

const app = createApp(App);

app.config.globalProperties.$getslug = function createSlug(title) {
    return title
        .toLowerCase() 
        .trim()                        
        .replace(/[^a-z0-9\s-]/g, '')    
        .replace(/\s+/g, '-')           
        .replace(/-+/g, '-');            
}

app.use(VueTransitions);
app.use(LazyLoad, {component: true});
app.use(pluginPwaInstallPrompt)
app.config.globalProperties.$axios = axios;
app.use(router)
app.mount('#app');
