<template>
    <singlepostview v-if="postid != null" />
    <div v-if="postid === null">
        <div class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2  text-center mt-4">
            <a
                v-for="post in posts"
                :key="post.id"
                class="w-full rounded-lg shadow-md flex transition-transform transform hover:scale-105 h-52" 
                :href="'/blog/'+$getslug(post.title)+'/?postid=' + post.id"
            >
                <div v-if="post.main_image" class="flex-shrink-0 w-32 bg-white rounded-l-lg overflow-hidden flex items-center justify-center">
                    <img
                        class="max-h-full object-cover p-4"
                        :src="post.main_image"
                        :alt="post.title"
                    />
                </div>
                <div class="flex-1 p-4 bg-gray-100 rounded-r-lg flex flex-col justify-between">
                    <h2 class="font-semibold text-lg mb-1">{{ post.title }}</h2>
                    <p class="text-sm text-gray-600">Data di pubblicazione: {{ post.date }}</p>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import singlepostview from './singlepost.vue';

export default {
    name: "allPost",
    components: {
        singlepostview
    },
    data() {
        return {
            posts: [],
            postid: null,
            loading: false,
            errored: false
        };
    },
    async mounted() {
        this.postid = await (new URLSearchParams(window.location.search)).get('postid');
        document.title = `Blog | piovonoofferte.it`;
        this.getPosts();
    },
    methods: {

        async getPosts() {
            this.loading = true;
            try {
                const response = await this.$axios.get(`${this.$root.selectedServerDomain}/getPosts?start=0&stop=10000`, {
                    headers: { "Bypass-Tunnel-Reminder": 2 }
                });
                this.posts = response.data.posts;
                console.log(this.posts);
            } catch (error) {
                console.log(error);
                this.errored = true;
            } finally {
                this.loading = false;
            }
        },
        openPost(id) {
            window.open(`https://piovonoofferte.it/blog?postid=${id}`);
        }
    }
};
</script>

<style scoped>
/* Add some additional styles for Material Design aesthetics */
.grid {
    display: grid;
    gap: 16px; /* Gap between grid items */
}

.shadow-md {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.transition-transform {
    transition: transform 0.2s;
}

.hover\:scale-105:hover {
    transform: scale(1.05);
}

.rounded-lg {
    border-radius: 8px; /* Rounded corners */
}

.bg-white {
    background-color: white; /* Background color for the card */
}


</style>
