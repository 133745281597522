function splitIntoSentences(text) {
    // Usa una regex che non separa frasi su punti seguiti da numeri
    const sentenceRegex = /(?<!\d)\.(?!\d)|[!?]+/g;
    const sentences = text.split(sentenceRegex);
    return sentences.map(sentence => sentence.trim() + (sentence.trim().slice(-1).match(/[.!?]/) ? '' : '.'));
}

// Funzione per rimuovere parole comuni (stopwords)
function removeStopwords(words) {
    const stopwords = new Set([
        "il", "la", "i", "le", "e", "di", "a", "in", "per", "con", "che", "da", "non", "su", "un", "una", "ma", "come", "o", "è", "al", "ai", "del",
        "della", "delle", "degli", "nel", "nella", "nelle", "sul", "sulla", "sulle", "già", "ancora", "questo", "quello", "lui", "lei", "noi", "voi",
        "loro", "questi", "queste", "quelli", "quale", "qual", "alcuni", "alcune", "ogni", "tutti", "tutte", "alcun", "unico", "unica", "dopo", "prima",
        "ora", "poi", "anche", "ma", "se", "perché", "cosa", "come", "dove", "quando", "chi", "che", "quale", "qual", "fino", "quand'anche", "poco", "molto",
        "tanto", "tante", "una volta", "ancora", "essere", "avere", "far", "fare", "andare", "venire", "stare", "dire", "guardare", "ascoltare", "parlare",
        "scrivere", "pensare", "sapere", "volere", "potere", "dovere", "più", "meno", "questo", "quella", "ogni", "tra", "verso", "su", "giù", "qua", "là",
        "sì", "no", "per", "senza", "contro", "tra", "fra", "dentro", "fuori", "vicino", "lontano", "sotto", "sopra", "lato", "dietro", "davanti",
        "mentre", "tra", "finché", "poiché", "sebbene", "se", "anche", "ci", "là", "là", "lì", "allora", "di", "alcuni", "quelli", "quella", "adesso", 
        "adesso", "dove", "solo", "prima", "già", "quasi", "certo", "qualcosa", "qualcuno", "nessuno", "tanto", "pochi", "molti", "quanto", "come",
        "perciò", "quindi", "comunque", "però", "infatti", "addirittura", "magari", "anziché", "invece", "ma", "però", "finora", "tuttavia", "dunque",
        "certo", "appena", "quindi", "tanto", "almeno", "solo", "neppure", "nemmeno", "soltanto", "altrimenti", "soprattutto", "invece", "però",
        "sennò", "anzi", "eppure", "oppure", "altrimenti", "appunto", "così", "cioè", "tuttavia", "in sostanza", "in effetti", "soprattutto", "solitamente", 
        "in genere", "di solito", "in realtà", "perciò", "inoltre", "così", "pertanto", "allora", "cioè", "insomma", "considerando", "considerando che", 
        "comunque", "tanto che", "così che", "come se", "a meno che", "affinché", "eccetto", "senza dubbio", "ogni volta che", "fintanto che",
        "dunque", "ciò", "tale", "tanto", "tuttora", "ogni", "chiunque", "ovunque", "talvolta", "spesso", "purtroppo", "tuttavia", "perciò", "sì", "forse",
        "solo", "almeno", "finora", "anzi", "d'altro canto", "infine", "dopo tutto", "finché", "allora", "neanche", "oppure", "pure", "sia", "seppure",
        "comunque", "tuttavia", "insomma", "in sostanza", "in effetti", "soprattutto", "solitamente", "in genere", "di solito", "in realtà", "perciò",
        "inoltre", "così", "pertanto", "allora", "cioè", "insomma", "ripeto", "riassumendo", "per quanto riguarda",
    ]);
    return words.filter(word => !stopwords.has(word));
}

// Funzione per calcolare la frequenza delle parole
function calculateWordFrequency(text) {
    const words = text.toLowerCase().match(/\w+(\.\w+)?/g) || []; // Rileva anche numeri con decimali
    const filteredWords = removeStopwords(words);
    const frequency = {};

    filteredWords.forEach(word => {
        frequency[word] = (frequency[word] || 0) + 1;
    });

    return frequency;
}

// Funzione per calcolare il punteggio delle frasi
function calculateSentenceScores(sentences, wordFrequency) {
    return sentences.map(sentence => {
        const words = sentence.toLowerCase().match(/\w+(\.\w+)?/g) || []; // Rileva anche numeri con decimali
        let score = 0;

        words.forEach(word => {
            score += wordFrequency[word] || 0;
        });

        return { sentence, score };
    });
}

// Funzione per selezionare la porzione più rilevante di una frase lunga
function extractRelevantPortion(sentence, wordFrequency, maxWords) {
    const words = sentence.split(' ');
    if (words.length <= maxWords) return fixPunctuation(sentence); // Se la frase è sotto la soglia, restituisci tutta la frase

    // Dividi la frase in porzioni e calcola il punteggio per ogni porzione
    const portions = [];
    const windowSize = maxWords; // La grandezza della porzione da selezionare

    for (let i = 0; i <= words.length - windowSize; i++) {
        const portion = words.slice(i, i + windowSize).join(' ');
        const score = portion
            .toLowerCase()
            .match(/\w+(\.\w+)?/g)
            .reduce((acc, word) => acc + (wordFrequency[word] || 0), 0);
        
        portions.push({ portion, score });
    }

    // Seleziona la porzione con il punteggio più alto e correggi la punteggiatura
    let bestPortion = portions.sort((a, b) => b.score - a.score)[0].portion;

    // Corregge le parentesi incomplete nella porzione selezionata
    bestPortion = fixUnbalancedParentheses(bestPortion);

    return fixPunctuation(bestPortion.trim());
}

// Funzione per suggerire il numero ottimale di frasi da includere nel riassunto
function suggestMaxSentences(text) {
    const sentences = splitIntoSentences(text);
    const wordFrequency = calculateWordFrequency(text);
    
    const uniqueWords = new Set(Object.keys(wordFrequency));
    const repetitionPenalty = Math.max(1, Math.floor(uniqueWords.size / sentences.length));

    const optimalCount = Math.ceil(sentences.length / repetitionPenalty); 
    return Math.max(1, Math.min(optimalCount, sentences.length));
}

// Funzione per correggere la punteggiatura, aggiungendo un punto finale se mancante
function fixPunctuation(sentence) {
    sentence = sentence.trim();

    // Rimuove ",." e controlla l'equilibrio delle parentesi
    sentence = sentence.replace(/,\.$/, '.').replace(/([,.!?])\1+/g, '$1');

    // Aggiungi un punto finale se manca
    if (!sentence.match(/[.!?]$/)) {
        sentence += '.';
    }

    return sentence;
}

// Funzione per correggere parentesi sbilanciate
function fixUnbalancedParentheses(text) {
    let openCount = 0;
    let closeCount = 0;

    // Controlla il numero di parentesi aperte e chiuse
    for (const char of text) {
        if (char === '(') openCount++;
        if (char === ')') closeCount++;
    }

    // Aggiungi parentesi chiuse se ci sono parentesi aperte non chiuse
    while (openCount > closeCount) {
        text += ')';
        closeCount++;
    }

    return text;
}

// Funzione principale per riassumere il testo
function summarizeText(text, userMaxSentences, maxWordsPerSentence = 20) {
    const sentences = splitIntoSentences(text);
    const wordFrequency = calculateWordFrequency(text);

    if (sentences.length === 0) {
        return "";
    }

    const suggestedMax = suggestMaxSentences(text);
    const maxSentences = userMaxSentences > suggestedMax ? suggestedMax : userMaxSentences;

    // Valuta il punteggio delle frasi
    const scoredSentences = calculateSentenceScores(sentences, wordFrequency);
    
    // Seleziona le frasi con punteggio più alto
    const topSentences = scoredSentences.slice(0, maxSentences).map(item => {
        const sentence = item.sentence;
        
        // Se la frase è troppo lunga, prendi la parte più rilevante
        return sentence.split(' ').length > maxWordsPerSentence
            ? extractRelevantPortion(sentence, wordFrequency, maxWordsPerSentence)
            : fixPunctuation(sentence);
    });

    return topSentences.join(' ').trim();
}


module.exports = { summarizeText };
