<template>
    <div class="flex">
      <Login v-if="!isLoggedIn" @loggedIn="isLoggedIn=true" />
      <div v-else class="flex">
        <Menu />
        <MarkdownEditor />
      </div>
    </div>
  </template>
  
  <script>
  import Login from './login.vue';
  import Menu from './menu.vue';
  import MarkdownEditor from './markdowneditor.vue';
  
  export default {
    components: {
      Login,
      Menu,
      MarkdownEditor,
    },
    data() {
      return {
        isLoggedIn: localStorage.getItem('loggedIn') === 'true',
      };
    },
  };
  </script>
  
  <style scoped>
  /* Stili opzionali per personalizzare il componente principale */
  </style>