<template>
  <div v-if="post != undefined" class="text-left" :class="{'lg:px-48': passedMd === undefined}">
    <Markdown class="mx-5" :breaks="true" :source="post.body"></Markdown>
    <div class=" border-t border-gray-300"></div>
    <similarPosts v-if="post != undefined" :asin="post.product_info.asin" :titolo="post.product_info.title"></similarPosts>
  </div>
</template>

<script>
import Markdown from 'vue3-markdown-it';
import 'highlight.js/styles/monokai.css';
import similarPosts from './similarPosts.vue';

export default {
  name: "allPost",
  components: {
    Markdown,
    similarPosts
  },
  props: {
    passedMd: String
  },
  data() {
    return {
      postidparam: (new URLSearchParams(window.location.search)).get('postid'),
      post: undefined,
      loading: false,
      errored: false
    };
  },
  mounted() {
    if (this.passedMd != undefined) {
      this.post.body = this.passedMd;
    } else {
      this.getPost();
    }
    setTimeout(() => { this.manageStyle() }, 10);
  },
  methods: {
    getPost() {
      this.loading = true;
      this.$axios
        .get(`${this.$root.selectedServerDomain}/getPostById?id=${this.postidparam}`, { headers: { "Bypass-Tunnel-Reminder": 2 } })
        .then((response) => {
          this.post = response.data.post;

          this.loading = false;

          // SEO Optimization: Update page title and meta description
          document.title = `${this.post.title} | piovonoofferte.it`;
          this.updateMetaTags(this.post.title, this.generateMetaDescription(this.post));
          setTimeout(() => { this.manageStyle() }, 20);

          this.addSchemaMarkup(this.post)

        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => { this.loading = false });
    },
    updateMetaTags(title, description) {
      document.title = title;

      // Update or create meta description
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.name = "description";
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = description;

      // Update or create Open Graph title (for social media)
      let ogTitle = document.querySelector('meta[property="og:title"]');
      if (!ogTitle) {
        ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        document.head.appendChild(ogTitle);
      }
      ogTitle.content = title;

      // Update or create Twitter title (for Twitter cards)
      let twitterTitle = document.querySelector('meta[name="twitter:title"]');
      if (!twitterTitle) {
        twitterTitle = document.createElement('meta');
        twitterTitle.name = 'twitter:title';
        document.head.appendChild(twitterTitle);
      }
      twitterTitle.content = title;

      // Update or create canonical link
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      if (!canonicalLink) {
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = window.location.href;
        document.head.appendChild(link);
      } else {
        canonicalLink.href = window.location.href;
      }
    },
    generateMetaDescription(post) {
        if(post.metadescription == null){
          let body = post.body
          const cleanBody = body
            .replace(/!\[.*?\]\(.*?\)/g, '') // Remove Markdown image syntax
            .replace(/PIOVONOOFFERTETAG-PRODUCT-[A-Z0-9]{10}/g, ''); // Remove variable ASINs

          const plainText = cleanBody
              .replace(/#+\s*/g, '') // Remove Markdown headers
              .replace(/\s+/g, ' ')   // Replace multiple spaces with a single space
              .trim();                // Trim leading and trailing whitespace

          const metaDescription = plainText.substring(0, 160).trim();

          return metaDescription.length > 0 ? `${metaDescription}${metaDescription.endsWith('.') ? '' : '.'}` : 'Discover more about this product!';
        }else{
          return post.metadescription
        }
    },
    addSchemaMarkup(post) {
      const convertedDate = (dateString) => {
        const date = new Date(dateString.split('/').reverse().join('-') + 'T00:00:00Z');
        if (isNaN(date)) {
          console.error("Date conversion error for:", dateString);
        }
        return date.toISOString();
      };

      const schema = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": post.title,
        "description": this.generateMetaDescription(post),
        "author": {
          "@type": "Person",
          "name": "Piovonoofferte Team"
        },
        "datePublished": convertedDate(post.date), // Assicurati che la data sia nel formato corretto (ISO 8601)
        "image": post.product_info.image || "", 
        "articleBody": post.body
      };

      console.log("Schema markup:", schema); // Log per verifica

      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.textContent = JSON.stringify(schema);
      document.head.appendChild(script);
    },
    manageStyle() {
      const parentDiv = this.$el;

      parentDiv.querySelectorAll('h1').forEach(h1 => {
        h1.style.fontSize = '28px';
        h1.style.marginTop = '20px';
        h1.style.marginBottom = '20px';
        h1.classList.add('font-bold');
        const borderDiv = document.createElement('div');
        borderDiv.classList.add('border-b', 'border-gray-300', 'pb-2');
        h1.appendChild(borderDiv);
      });

      parentDiv.querySelectorAll('h2').forEach(h2 => {
        h2.style.fontSize = '24px';
        h2.style.marginTop = '15px';
        h2.style.marginBottom = '15px';
        h2.classList.add('font-semibold');
        h2.style.color = '#333';
      });

      parentDiv.querySelectorAll('h3').forEach(h3 => {
        h3.style.fontSize = '20px';
        h3.style.marginTop = '18px';
        h3.style.marginBottom = '10px';
        h3.classList.add('font-semibold');
      });

      parentDiv.querySelectorAll('img').forEach(img => {
        const newDiv = document.createElement('div');
        newDiv.classList.add('imagecenter', 'flex', 'items-center', 'justify-center', 'mb-4');
        const newImg = img.cloneNode(true);
        newImg.style.maxHeight = '350px';
        newImg.style.objectFit = 'contain';
        newDiv.appendChild(newImg);
        img.parentNode.replaceChild(newDiv, img);
      });

      parentDiv.querySelectorAll('p').forEach(paragraph => {
        paragraph.style.marginBottom = '12px';
        paragraph.style.lineHeight = '1.8';
        paragraph.style.color = '#555';
        paragraph.classList.add('text-base');
      });

      parentDiv.querySelectorAll('ul').forEach(ul => {
        ul.style.listStyleType = 'disc';
        ul.style.marginLeft = '20px';
        ul.style.marginBottom = '12px';
        ul.classList.add('list-inside');
      });

      parentDiv.querySelectorAll('ol').forEach(ol => {
        ol.style.listStyleType = 'decimal';
        ol.style.marginLeft = '20px';
        ol.style.marginBottom = '12px';
        ol.classList.add('list-inside');
      });

      parentDiv.querySelectorAll('li').forEach(li => {
        li.style.marginBottom = '5px';
        li.classList.add('ml-2');
      });

      parentDiv.querySelectorAll('blockquote').forEach(blockquote => {
        blockquote.style.fontStyle = 'italic';
        blockquote.style.borderLeft = '4px solid #ccc';
        blockquote.style.paddingLeft = '15px';
        blockquote.style.margin = '15px 0';
        blockquote.style.color = '#555';
        blockquote.style.backgroundColor = '#f9f9f9';
        blockquote.style.borderRadius = '5px';
        blockquote.style.padding = '10px 15px';
      });

      parentDiv.querySelectorAll('a').forEach(anchor => {
        anchor.style.color = 'blue';
        anchor.style.textDecoration = 'underline';
      });

      parentDiv.querySelectorAll('table').forEach(table => {
        const container = document.createElement('div');
        container.classList.add('table-container');
        table.parentNode.replaceChild(container, table); // Replace table with container
        container.appendChild(table); // Append the table to the new container

        // Additional styles (if needed)
        table.style.margin = '0 auto'; // Center table within its container
        table.style.maxWidth = '100%'; // Ensure it doesn’t overflow
      });

      parentDiv.querySelectorAll('p').forEach(paragraph => {
        if (paragraph.textContent.match(/^PIOVONOOFFERTETAG-PRODUCT-/)) {
          paragraph.innerHTML = `<iframe class="mt-4" frameborder="0" height="280" width="100%" allowfullscreen src="/productcard?asin=${paragraph.textContent.replace("PIOVONOOFFERTETAG-PRODUCT-", "")}&smalltitle=true"></iframe>`;
        }
      });
    }
  }
}
</script>

<style>
.h_iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.imagecenter {
  margin: 20px 0;
}
.table-container {
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  margin: 20px 0;   /* Add vertical spacing */
}

table {
  width: 100%;         /* Full width of the container */
  border-collapse: collapse; /* Merge borders */
  margin: auto;       /* Center the table */
}

th, td {
  padding: 12px;        /* Add padding */
  text-align: left;     /* Align text to the left */
  border-bottom: 1px solid #ddd; /* Bottom border for separation */
}

th {
  background-color: #f5f5f5; /* Light gray background for header */
  font-weight: bold;         /* Bold text for header */
}

tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}
</style>
