<template>
    <div class="flex justify-center items-center h-screen">
      <div class="bg-white p-8 rounded shadow-lg">
        <h2 class="text-2xl mb-4">Login</h2>
        <input v-model="username" type="text" class="border p-2 mb-4 w-full" placeholder="Username">
        <input v-model="password" type="password" class="border p-2 mb-4 w-full" placeholder="Password">
        <button @click="login" class="bg-blue-500 text-white py-2 px-4 rounded">Login</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        username: '',
        password: '',
      };
    },
    name:'loginP',
    methods: {
      login() {
        // Effettua il login e memorizza la sessione nel localStorage
        if (this.username && this.password) {
          localStorage.setItem('loggedIn', 'true');
          // Altri passaggi di autenticazione potrebbero essere necessari
          this.$emit('loggedIn')
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Stili opzionali per personalizzare il componente di login */
  </style>
  