<template>
  <div class="flex flex-col md:flex-row p-6">
    <!-- Colonna sinistra per l'input Markdown -->
    <div class="w-full md:w-1/2 p-4">
      <h2 class="text-3xl mb-6 font-semibold">Scrivi in Markdown</h2>

      <!-- Textarea per l'input Markdown -->
      <textarea
        v-model="inputText"
        class="border p-3 w-full h-48 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        @input="inputF()"
        placeholder="Inserisci il contenuto in markdown..."
      ></textarea>

      <!-- Input per l'asin -->
      <div class="mt-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">ASIN (Opzionale)</label>
        <input
          v-model="asin"
          class="border p-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="text"
          placeholder="Inserisci l'ASIN"
        />
      </div>

      <!-- Textarea per otherinstruct -->
      <div class="mt-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">Istruzioni Aggiuntive (Opzionale)</label>
        <textarea
          v-model="otherinstruct"
          class="border p-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 h-24"
          placeholder="Inserisci altre istruzioni"
        ></textarea>
      </div>

      <!-- Pulsante di invio -->
      <div class="flex items-center mt-6">
        <button
          @click="submitArticle"
          :disabled="isLoading"
          class="ml-4 bg-blue-500 text-white py-2 px-6 rounded-lg flex items-center hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-gray-400"
        >
          <span v-if="isLoading" class="loader"></span>
          <span v-if="!isLoading">{{ buttonText }}</span>
        </button>
      </div>

      <!-- Messaggio di stato -->
      <p class="mt-4 text-gray-600">{{ loadingMessage }}</p>
    </div>

    <!-- Colonna destra per l'anteprima Markdown -->
    <div class="w-full md:w-1/2 p-4">
      <h2 class="text-3xl mb-6 font-semibold">Markdown Elaborato</h2>
      <singlepost :passedMd="inputText" :key="inputText" />
    </div>
  </div>
</template>

<script>
import singlepost from '../blog/singlepost.vue';
import axios from 'axios'; // Assicurati di avere axios installato

export default {
  data() {
    return {
      inputText: '',
      asin: '', // Aggiunto campo asin
      otherinstruct: '', // Aggiunto campo otherinstruct
      isLoading: false,
      loadingMessage: '',
      buttonText: 'Invia Articolo',
    };
  },
  name: 'mdEditor',
  components: {
    singlepost,
  },
  methods: {
    inputF() {
      console.log(this.inputText);
    },
    async submitArticle() {
      this.isLoading = true;
      this.loadingMessage = 'Invio in corso...';
      this.buttonText = 'Inviando...';

      try {
        const response = await axios.post('https://api.piovonoofferte.it/writeanarticlewithgemini', {
          markdown: this.inputText,
          asin: this.asin || null, // Invio asin se presente, altrimenti null
          otherinstruct: this.otherinstruct || null, // Invio otherinstruct se presente, altrimenti null
        });

        this.loadingMessage = `Articolo inviato con successo: ${response.data.message || 'Nessun messaggio disponibile.'}`;
      } catch (error) {
        this.loadingMessage = `Errore nell'invio: ${error.message || 'Errore sconosciuto.'}`;
      } finally {
        this.isLoading = false;
        this.buttonText = 'Invia Articolo';
      }
    },
  },
};
</script>

<style scoped>
.loader {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: white;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Personalizzazioni di stile con Tailwind */
</style>
