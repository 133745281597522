<template>
    <h2 class="text-2xl font-semibold text-gray-900 m-4"> Potrebbero interessarti anche: </h2>
    <div class="flex overflow-x-auto space-x-4">
      <a 
        v-for="(product, index) in similarProducts" 
        :key="index" 
        class="bg-gray-100 shadow-lg rounded-lg flex-none w-64 mb-6" :href='"/"+$getslug(product.product_info.title)+"/?product="+product.asin'
      >
        <div class="flex-shrink-0 h-40 bg-white rounded-t-lg overflow-hidden flex items-center justify-center">
          <img
            class="max-h-full max-w-full object-contain p-2"
            :src="product.product_info.image"
            :alt="product.product_info.title"
          />
        </div>
        <div class="p-2 bg-gray-100">
          <h2 class="text-md font-semibold text-gray-900 truncate-3-lines">
            {{ product.product_info.title }}
          </h2>
          <div v-if="product.product_info.noDealPrice != null && product.product_info.noDealPrice > product.product_info.price" class="flex items-center justify-center my-2">
            <p class="text-slate-700 line-through text-sm mx-2">
              {{ product.product_info.noDealPrice.toFixed(2) }}€
            </p>
            <p class="text-white bg-red-600 rounded px-2 text-sm">
              -{{ parseInt(100 - (product.product_info.price * 100) / product.product_info.noDealPrice) }}%
            </p>
          </div>
          <p class="text-sky-500 text-xl font-bold">
            {{ product.product_info.price.toFixed(2) }}€
          </p>
  
          <h3 v-if="product.product_info.hasCoupon" class="text-slate-500 mt-2">
            Coupon: {{ product.product_info.coupon }}
          </h3>
  
        </div>
    </a>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      asin: {
        type: String,
        required: true
      },
      categoria: {
        type: String,
        required: true
      },
      titolo: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        similarProducts: []
      };
    },
    methods: {
      fetchSimilarProducts() {
        const payload = {
          asin: this.asin,
          category: this.categoria,
          title: this.titolo
        };
        
        this.$axios.post(`${this.$root.selectedServerDomain}/getSimilarProduct`, payload)
          .then(response => {
            this.similarProducts = response.data.similarproduct;
          })
          .catch(error => {
            console.error("Errore nel recupero dei prodotti simili:", error);
          });
      }
    },
    mounted() {
      this.fetchSimilarProducts();
    }
  };
  </script>
  
  <style scoped>
  .truncate-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .flex {
    display: flex;
    flex-wrap: nowrap; /* Impedisce che le card vadano su più righe */
  }
  </style>
  