<template>
    <h2 class="text-2xl font-bold mb-4 pt-4">Cosa dicono gli utenti</h2>
    <div class="flex flex-col items-center">
      <!-- Star Rating Section -->
      <div class="flex items-center mb-4">
        <div class="flex">
          <span v-for="star in maxStars" :key="star" class="text-yellow-500">
            <svg v-if="star <= parsedRating" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 15.27L16.18 20 14.54 12.97 20 8.24l-8.19-.69L10 0 8.19 7.55 0 8.24l5.46 4.73L3.82 20z" />
            </svg>
            <svg v-else class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 15.27L16.18 20 14.54 12.97 20 8.24l-8.19-.69L10 0 8.19 7.55 0 8.24l5.46 4.73L3.82 20z" fill="none" stroke="currentColor" stroke-width="2"/>
            </svg>
          </span>
        </div>
        <span class="ml-2 text-gray-600">{{ parsedRating.toFixed(1) }} stelle su 5</span>
      </div>
      <div class="mt-2 lg:columns-2">
        <div 
          v-for="(review, index) in filteredReviews" 
          :key="index" 
          :class="getBackgroundColor(review.tone)" 
          class="p-4 mb-4 rounded-md shadow-md transition duration-300 ease-in-out break-inside-avoid-column"
          @click="toggleExpand(index)"
        >
          <p v-if="expandedReviewIndex != index && !isPrerender" class="text-md">{{ review.summary }}</p>
          <transition name="expand">
            <div v-show="expandedReviewIndex === index || isPrerender" class="mt-2 text-gray-700">
              <p v-html="getFormattedReviewText(review.originalText, review.summary)"></p>
            </div>
          </transition>
          <button 
            v-if="expandedReviewIndex != index" 
            :class="getToneClass(review.tone)" 
            class="text-sm font-medium focus:outline-none"
          >
            Leggi recensione
          </button>
          <button 
            v-if="expandedReviewIndex === index" 
            :class="getToneClass(review.tone)" 
            class="text-sm font-medium focus:outline-none"
          >
            Nascondi recensione
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { franc } from 'franc';
  import langs from 'langs';
  import Sentiment from 'sentiment';
  import { summarizeText } from '../textSummarizer.js';
  
  export default {
    name: 'ReviewSummarizer',
    props: {
      reviews: {
        type: Array,
        required: true,
        default: () => [] // Provide a default empty array
      },
      rating: {
        type: String,
        required: true // Rating as a string
      }
    },
    data() {
      return {
        sentiment: new Sentiment(),
        filteredReviews: [],
        expandedReviewIndex: null,
        isPrerender: this.$root.isprerenderrequest,
        maxStars: 5 // Maximum number of stars
      };
    },
    computed: {
      parsedRating() {
        if (this.rating) {
          // Replace the comma with a dot and parse to float
          return parseFloat(this.rating.replace(',', '.')) || 0; // Fallback to 0 if parsing fails
        }
        return 0; // Return 0 if rating is undefined
      }
    },
    mounted() {
      this.processReviews();
    },
    methods: {
      async processReviews() {
        if (!this.reviews || this.reviews.length === 0) {
          this.filteredReviews = [];
          return;
        }
  
        this.filteredReviews = await Promise.all(this.reviews.map(async (review) => {
          const isItalian = this.detectLanguage(review);
          if (isItalian) {
            const summary = this.summarizeText(review).replace(/\.$/, ''); // Rimuovi il punto finale
            const tone = this.analyzeSentiment(summary);
            return { originalText: review, summary, tone };
          }
          return null;
        })).then(res => res.filter(review => review !== null));
      },
  
      detectLanguage(text) {
        const langCode = franc(text);
        const language = langs.where("3", langCode);
        return language && language.name === "Italian";
      },
  
      summarizeText(text) {
        return summarizeText(text, 1, 20);
      },
  
      analyzeSentiment(text) {
        const result = this.sentiment.analyze(text);
        return result.score > 0 ? 'positivo' : result.score < 0 ? 'negativo' : 'neutro';
      },
  
      getBackgroundColor(tone) {
        return (tone === 'positivo' || tone === 'neutro') ? 'bg-green-100' : 'bg-red-100';
      },
  
      getToneClass(tone) {
        return (tone === 'positivo' || tone === 'neutro') ? 'text-green-600' : 'text-red-600';
      },
  
      toggleExpand(index) {
        this.expandedReviewIndex = this.expandedReviewIndex === index ? null : index;
      },
  
      getFormattedReviewText(originalText, summary) {
        return originalText.replace(summary, `<strong>${summary}</strong>`);
      }
    }
  };
  </script>
  
  <style scoped>
  /* Animazione di espansione */
  .expand-enter-active, .expand-leave-active {
    transition: all 0.3s ease;
  }
  .expand-enter, .expand-leave-to {
    max-height: 0;
    opacity: 0;
  }
  .expand-enter-to, .expand-leave {
    max-height: 200px; /* Limita l'espansione per testi molto lunghi */
    opacity: 1;
  }
  </style>
  