<template>
    <div class="p-4">
      <h2 class="text-2xl mb-4">Menu</h2>
      <ul>
        <li @click="selectFunction('post')">Post</li>
        <li @click="selectFunction('product')">Gestione Prodotti</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name:'menuC',
    methods: {
      selectFunction(functionType) {
        // Implementa la logica per gestire la selezione di funzioni
        console.log(`Hai selezionato: ${functionType}`);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Stili opzionali per personalizzare il componente del menu */
  </style>