<template>
    <h2 class="text-2xl font-semibold text-gray-900 text-center m-4"> Potrebbero interessarti anche: </h2>
    <div class="flex overflow-x-auto space-x-4 py-4">
      <a 
        v-for="(post, index) in similarposts" 
        :key="index" 
        class="min-w-[330px] w-1/3 rounded-lg shadow-md flex transition-transform transform hover:scale-105 h-52"
        :href="'/blog/'+$getslug(post.post_title)+'/?postid=' + post.post_id"
      >
        <div v-if="post.product_info.image" class="flex-shrink-0 w-32 bg-white rounded-l-lg overflow-hidden flex items-center justify-center">
          <img
            class="max-h-full object-cover p-4"
            :src="post.product_info.image"
            alt="Immagine dell'articolo"
          />
        </div>
        <div class="flex-1 p-4 bg-gray-100 rounded-r-lg flex flex-col justify-between">
          <h2 class="truncate-3-lines font-semibold text-lg mb-1">{{ post.post_title }}</h2>
          <p class="text-sm text-gray-600">Data di pubblicazione: {{ post.post_date }}</p>
        </div>
      </a>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      asin: {
        type: String,
        required: true
      },
      titolo: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        similarposts: []
      };
    },
    methods: {
      fetchSimilarProducts() {
        const payload = {
          asin: this.asin,
          product_title: this.titolo
        };
        
        this.$axios.post(`${this.$root.selectedServerDomain}/getSimilarPosts`, payload)
          .then(response => {
            this.similarposts = response.data.similarposts;
            console.log('aaai', response.data);
          })
          .catch(error => {
            console.error("Errore nel recupero dei prodotti simili:", error);
          });
      }
    },
    mounted() {
      this.fetchSimilarProducts();
    }
  };
  </script>
  
  <style scoped>
  .truncate-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .flex {
    display: flex;
    flex-wrap: nowrap; /* Prevent cards from stacking vertically */
  }
  </style>
  